.landingLogo {
    padding: 20px;
    display: table;
    margin: 0 auto;
    height: 75px;
}

.loginControls {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
}

.title {
    font-size: 3em;
    margin: 0;
    padding: 80px 0 20px 0;
}

.bitSmall {
    font-size: 0.8em;
}

.loginButton,
.eller {
    margin-top: 1em !important;
}

.googleButton {
    background-color: #fff !important;
}