.dashboard {
    display: grid;
    grid-template-rows: max-content max-content max-content 1fr;
    box-sizing: border-box;
    height: 100vh;
}

.selection {
    padding: 10px;
    width: max-content;
    display: table;
    margin: 0 auto;
}

.selection > .message {
    margin: 0px auto 12px auto;
    background-color: #ffffff84; ;
    width: 90%;
    max-width: 500px;
    padding: 12px;
    border-radius: 5px;
}

.selection > .message > p {
    line-height: 1.5;
}

.content {
    padding: 10px;
}

.tables {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.tables>* {
    padding: 10px;
}

/*
    header
*/
.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.logo {
    padding: 20px;
    justify-self: start;
    height: auto;
    width: 143px;
}

.logoutBox {
    display: flex;
    flex-direction: row;
    justify-self: end;
    text-align: right;
    padding: 10px;
    align-items: center;
    column-gap: 10px;
    ;
}

.logout {
    display: inline;
    background: none;
    border: none;
}

.logout:hover {
    color: #000;
    cursor: pointer;
}


.tableWrapper {
    height: fit-content;
    overflow: auto;
    display: block;
    width: 100%;
    position: relative;
    max-height: 700px;
}

.tableWrapper thead {
    position: sticky;
    top: 0;
    left: 0;
}

.csvButton {
    margin-top: 20px !important;
}

.datepicker {
    width: 240px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    border-left-color: rgba(34, 36, 38, 0.15);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgba(34, 36, 38, 0.15);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgba(34, 36, 38, 0.15);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-color: rgba(34, 36, 38, 0.15);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 9.5px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 9.5px;
    text-align: center;
    background-color: rgb(255, 255, 255);
}

@media only screen and (max-width: 1020px) {
    .header {
        width: 100vw;
        align-items: center;
    }

    .username {
        display: none;
    }

    .tables {
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .selection {
        width: 100vw;
        margin: 0;
    }

    .statistics {
        width: 100vw;
        margin: 0 !important;
        padding: 10px;
        gap: 10px !important;
        place-items: stretch !important;
        box-sizing: border-box !important;
    }

    .statistic {
        width: 100% !important;
        overflow: hidden;
        font-size: 0.8em;
        padding: 8px !important;
        min-height: 56px;
    }

    .statistic h3 {
        font-size: 1.2em;
    }
}

@media only screen and (min-width: 1021px) {
    .statistic {
        height: 72px;
    }
}